import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Icon,
    IconButton,
    InputAdornment,
    InputLabel,
    LinearProgress,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useAppSelector } from 'app/hooks'
import dayjs from 'dayjs'
import {
    getEmpresaListSelector,
    getGeneroListSelector,
    getTipoDocumentoListSelector
} from 'features'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { insertClienteService } from 'services'
import { getInfoClienteFromIntegrationService } from 'services/integration/integration.service'
import { ICliente, IPublicFormError } from 'types'
import { validateClientPublicForm } from 'utils'


interface Props {
    empresaId: number
}

export const FormContent = (props: Props) => {
    const { empresaId } = props
    const tipoDocumentoList = useAppSelector(getTipoDocumentoListSelector)
    const generoList = useAppSelector(getGeneroListSelector)
    const empresaList = useAppSelector(getEmpresaListSelector)
    const [data, setData] = React.useState<ICliente>({} as ICliente)
    const [alerts, setAlerts] = React.useState<IPublicFormError[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const [loading2, setLoading2] = React.useState<boolean>(false)
    const [certificado, setCertificado] = React.useState<string>()

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const campaña = queryParams.get('c') ?? ""

    const haveError = (field: keyof ICliente) => {
        return alerts.some((error) => error.key === field)
    }

    const errorMessages = (field: keyof ICliente) => {
        const error = alerts.find((error) => error.key === field)
        return error?.message
    }

    const handleChangeErrorFields = (errorFields: IPublicFormError[]) => {
        setAlerts(errorFields)
    }

    const changeValueEntity = (key: keyof ICliente, value: ICliente[keyof ICliente]) => {
        const errorFields = validateClientPublicForm(data, empresaList, empresaId)
        if (haveError(key)) {
            const updatedErrorFields = errorFields.filter(error => error.key !== key)
            handleChangeErrorFields(updatedErrorFields)
        }
        setData((prev) => ({ ...prev, [key]: value }))
    }

    const getValueData = (key: keyof ICliente): ICliente[keyof ICliente] => {
        const value = data[key]
        return value ?? ''
    }

    const getPrivacidadLink = (empresaId: number) => {
        if (empresaId === 1) {
            return 'https://avisoprivacidad.superdeporte.com.ec'
        }
        if (empresaId === 2 || empresaId === 3) {
            return 'https://avisoprivacidad.mundodeportivo-ec.com'
        }
        if (empresaId === 4) {
            return 'https://avisoprivacidad.equinox-ec.com'
        }
    }

    const getTerminosLink = (empresaId: number) => {
        if (empresaId === 1) {
            return 'https://terminos.superdeporte.com.ec'
        }
        if (empresaId === 2) {
            return 'https://terminos-adidas.mundodeportivo-ec.com'
        }
        if (empresaId === 3) {
            return 'https://terminos.mundodeportivo-ec.com'
        }
        if (empresaId === 4) {
            return 'https://terminos.equinox-ec.com'
        }
    }


    const handleSearchClientInfo = async () => {
        if (data.numDocumento !== undefined) {
            setLoading(true)
            const empresa = empresaList.filter((emp) => emp.id === empresaId)
            if (empresa !== undefined) {
                try {
                    const clienteIntegration = await getInfoClienteFromIntegrationService(empresa[0].identificacion, data.numDocumento)
                    const clienteArray = clienteIntegration.nombres.split(' ')
                    const filteredArray = clienteArray.filter((nombre) => nombre !== '')
                    if (filteredArray.length === 1) {
                        changeValueEntity('nombres', filteredArray[0])
                    } else if (filteredArray.length === 2) {
                        changeValueEntity('nombres', filteredArray[0])
                        changeValueEntity('apellidos', filteredArray[1])
                    } else if (filteredArray.length === 3) {
                        changeValueEntity('nombres', filteredArray[0])
                        changeValueEntity('apellidos', filteredArray.filter((element, index) => index !== 0).join(' '))
                    } else if (filteredArray.length > 3) {
                        changeValueEntity('nombres', filteredArray.filter((element, index) => index < 2).join(' '))
                        changeValueEntity('apellidos', filteredArray.filter((element, index) => index > 1).join(' '))
                    }

                    if (clienteIntegration.email !== null) {
                        changeValueEntity('email', clienteIntegration.email)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            setLoading(false)
        } else {
            console.log('No hay numDocumento')
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setLoading2(true)
        const actualAlerts = validateClientPublicForm(data, empresaList, empresaId)
        if (actualAlerts.length > 0) {
            handleChangeErrorFields(actualAlerts)
        } else {
            const cliente = { ...data, esMayorEdad: true, empresa: empresaId, campaña }
            const response = await insertClienteService(cliente)
            if (response === 0) {
                const empresa = empresaList.find((empresa) => empresa.id === empresaId)
                actualAlerts.push({
                    typeError: 'success',
                    key: '',
                    message: `Muchas gracias por registrarte, ya te encuentras en nuestra base de datos.  
                    Si necesitas algún cambio en tu información, aplicar a tus derechos de suspender tu
                     suscripción para no recibir información de alguno de nuestros canales comerciales,
                      por favor comunícate con el mail ${empresa!.correo
                        }`,
                })
                handleChangeErrorFields(actualAlerts)
            } else {
                actualAlerts.push({
                    typeError: 'success',
                    key: '',
                    message: `Valoramos mucho la confianza depositada en nosotros con la aceptación
                     de nuestro Aviso de Privacidad.Hemos reforzado la protección de tus datos y
                     los utilizaremos para los fines que nos has autorizado.`,
                })
                handleChangeErrorFields(actualAlerts)
                setCertificado(response)
            }
        }
        setLoading2(false)
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <FormControl fullWidth error={haveError('tipoDocumento')} style={{ borderRadius: '0px' }}>
                        <InputLabel sx={{ color: '#C4C4C4' }}>Tipo de documento</InputLabel>
                        <Select
                            id="tipoDocumento"
                            name="tipoDocumento"
                            value={getValueData('tipoDocumento').toString()}
                            onChange={(event: SelectChangeEvent<string>) => {
                                changeValueEntity('tipoDocumento', event.target.value)
                            }}
                            label="Tipo de documento"
                            sx={{ borderRadius: '0px' }}
                        >
                            {tipoDocumentoList.map((tipoDocumento) => (
                                <MenuItem key={tipoDocumento.codigo} value={tipoDocumento.codigo}>
                                    {tipoDocumento.descripcion}
                                </MenuItem>
                            ))}
                        </Select>
                        {haveError('tipoDocumento') && (
                            <FormHelperText>{errorMessages('tipoDocumento')}</FormHelperText>
                        )}
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <TextField
                        id="numDocumento"
                        name="numDocumento"
                        label="Número de documento"
                        variant="outlined"
                        error={haveError('numDocumento')}
                        inputProps={{
                            maxLength: getValueData('tipoDocumento').toString() === '04' ? 10 : 13,
                            minLength: 10,
                        }}
                        helperText={errorMessages('numDocumento')}
                        value={getValueData('numDocumento')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            changeValueEntity(event.target.name as keyof ICliente, event.target.value)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" sx={{ mr: '2px' }}>
                                    <IconButton edge="end" onClick={handleSearchClientInfo}>
                                        <Icon sx={{ fontSize: 25 }}>search</Icon>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                {loading && (
                    <Box
                        sx={{
                            mx: 4,
                        }}
                    >
                        <LinearProgress />
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <TextField
                        id="nombres"
                        name="nombres"
                        label="Nombres"
                        variant="outlined"
                        error={haveError('nombres')}
                        helperText={errorMessages('nombres')}
                        value={getValueData('nombres')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            changeValueEntity(event.target.name as keyof ICliente, event.target.value.toUpperCase())
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <TextField
                        id="apellidos"
                        name="apellidos"
                        label="Apellidos"
                        variant="outlined"
                        error={haveError('apellidos')}
                        helperText={errorMessages('apellidos')}
                        value={getValueData('apellidos')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            changeValueEntity(event.target.name as keyof ICliente, event.target.value.toUpperCase())
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <FormControl error={haveError('genero')} sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                        <RadioGroup
                            row
                            sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}
                            value={getValueData('genero').toString()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                changeValueEntity('genero', event.target.value)
                            }}
                        >
                            {generoList.map((genero) => (
                                <FormControlLabel
                                    key={genero.codigo}
                                    value={genero.codigo}
                                    control={<Radio />}
                                    label={<Typography sx={{ fontWeight: '300' }}>{genero.descripcion}</Typography>}
                                />
                            ))}
                        </RadioGroup>
                        {haveError('genero') && (
                            <FormHelperText sx={{ color: 'red' }}>
                                {errorMessages('genero')}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        variant="outlined"
                        error={haveError('email')}
                        helperText={errorMessages('email')}
                        value={getValueData('email')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            changeValueEntity(event.target.name as keyof ICliente, event.target.value)
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <FormControl error={haveError('fechaNacimiento')}
                        sx={{
                            width: '100%',
                        }}>
                        <DatePicker
                            label="Fecha de nacimiento"
                            format="YYYY-MM-DD"
                            value={getValueData('fechaNacimiento') !== '' ? dayjs(getValueData('fechaNacimiento').toString()) : null}
                            onChange={(newValue) => {
                                if (newValue !== null) {
                                    changeValueEntity('fechaNacimiento', newValue.format('YYYY-MM-DD'))
                                }
                            }}
                        />
                        {haveError('fechaNacimiento') && (
                            <FormHelperText>{errorMessages('fechaNacimiento')}</FormHelperText>
                        )}
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <TextField
                        id="celular"
                        name="celular"
                        label="Celular"
                        variant="outlined"
                        error={haveError('celular')}
                        helperText={errorMessages('celular')}
                        type='tel'
                        inputProps={{
                            maxLength: 10,
                        }}
                        value={getValueData('celular')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            changeValueEntity(event.target.name as keyof ICliente, event.target.value)
                        }}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'left',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <FormControl
                        error={haveError('avisoPrivacidad')}
                        sx={{ width: '100%' }}
                    >
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!getValueData('avisoPrivacidad')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity('avisoPrivacidad', event.target.checked)
                                        }}
                                    />
                                }
                                label={
                                    <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                        He leído y acepto el{' '}
                                        <a href={getPrivacidadLink(empresaId)} rel="noreferrer" target="_blank">
                                            Aviso de Privacidad
                                        </a>{' '}
                                        para el tratamiento de mis datos personales.
                                    </Typography>
                                }
                            />
                        </FormGroup>
                        {haveError('avisoPrivacidad') && (
                            <FormHelperText sx={{ color: 'red' }}>
                                {errorMessages('avisoPrivacidad')}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'left',
                        mt: 4,
                        mx: 4,
                    }}
                >
                    <FormControl
                        error={haveError('terminos')}
                        sx={{ width: '100%' }}
                    >
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!getValueData('terminos')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity('terminos', event.target.checked)
                                        }}
                                    />
                                }
                                label={
                                    <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                        He leído y acepto los{' '}
                                        <a href={getTerminosLink(empresaId)} rel="noreferrer" target="_blank">
                                            Términos y Condiciones del Sorteo
                                        </a>
                                        .
                                    </Typography>
                                }
                            />
                        </FormGroup>
                        {haveError('terminos') && (
                            <FormHelperText sx={{ color: 'red' }}>
                                {errorMessages('terminos')}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        my: 2,
                        mx: 4,
                    }}
                >
                    {alerts.filter(type => type.typeError === 'success').map((alert, index) => (
                        <Box
                            key={'alert' + index.toString()}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                m: '4px',
                            }}
                        >
                            <Alert severity={'success'}>
                                <AlertTitle>{'Mensaje Exitoso'}</AlertTitle>
                                {alert.message}
                            </Alert>
                        </Box>
                    ))}
                </Box>

                {certificado && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            my: 2,
                            mx: 4,
                        }}
                    >
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', letterSpacing: '0.05em', pb: 1 }}>{'QR PARA TIENDAS:'}</Typography>
                        <img alt="qr" src={`https://newsletter.superdeporte.com.ec/api/cliente/qr?code=${certificado}`} width={150} />
                        <Typography sx={{ fontSize: '15px', fontWeight: '300', letterSpacing: '0.05em' }}>
                            <Box component="span" sx={{ fontSize: '14px', fontWeight: '400' }}>
                                CUPÓN PARA COMPRAS WEB:
                            </Box>
                            {certificado}
                        </Typography>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        mx: 4,
                    }}
                >
                    <Button variant="outlined" type="submit" disabled={loading2}>
                        REGISTRARSE
                    </Button>
                </Box>
                {loading2 && (
                    <Box
                        sx={{
                            mx: 4,
                        }}
                    >
                        <LinearProgress />
                    </Box>
                )}
            </form>
        </React.Fragment>
    )
}
